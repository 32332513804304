@tailwind base;
@tailwind components;
@tailwind utilities;

/* English Language Fonts */
@font-face {
    font-family: 'Figtree-Light';
    src: url('../public/fonts/Figtree-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'Figtree-Regular';
    src: url('../public/fonts/Figtree-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Figtree-Medium';
    src: url('../public/fonts/Figtree-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Figtree-SemiBold';
    src: url('../public/fonts/Figtree-SemiBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Figtree-Bold';
    src: url('../public/fonts/Figtree-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Figtree-ExtraBold';
    src: url('../public/fonts/Figtree-ExtraBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Figtree-Black';
    src: url('../public/fonts/Figtree-Black.ttf') format('truetype');
}

/* English Language Fonts - Italic */
@font-face {
    font-family: 'Figtree-LightItalic';
    src: url('../public/fonts/Figtree-LightItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'Figtree-RegularItalic';
    src: url('../public/fonts/Figtree-Italic.ttf') format('truetype');
}

@font-face {
    font-family: 'Figtree-MediumItalic';
    src: url('../public/fonts/Figtree-MediumItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'Figtree-SemiBoldItalic';
    src: url('../public/fonts/Figtree-SemiBoldItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'Figtree-BoldItalic';
    src: url('../public/fonts/Figtree-BoldItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'Figtree-ExtraBoldItalic';
    src: url('../public/fonts/Figtree-ExtraBoldItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'Figtree-BlackItalic';
    src: url('../public/fonts/Figtree-BlackItalic.ttf') format('truetype');
}

/* English Language Fonts - Mono */
@font-face {
    font-family: 'SplineSansMono-Light';
    src: url('../public/fonts/SplineSansMono-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'SplineSansMono-Regular';
    src: url('../public/fonts/SplineSansMono-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'SplineSansMono-Medium';
    src: url('../public/fonts/SplineSansMono-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'SplineSansMono-SemiBold';
    src: url('../public/fonts/SplineSansMono-SemiBold.ttf') format('truetype');
}

@font-face {
    font-family: 'SplineSansMono-Bold';
    src: url('../public/fonts/SplineSansMono-Bold.ttf') format('truetype');
}

/* English Language Fonts - Mono Italic */
@font-face {
    font-family: 'SplineSansMono-LightItalic';
    src: url('../public/fonts/SplineSansMono-LightItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'SplineSansMono-RegularItalic';
    src: url('../public/fonts/SplineSansMono-Italic.ttf') format('truetype');
}

@font-face {
    font-family: 'SplineSansMono-MediumItalic';
    src: url('../public/fonts/SplineSansMono-MediumItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'SplineSansMono-SemiBoldItalic';
    src: url('../public/fonts/SplineSansMono-SemiBoldItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'SplineSansMono-BoldItalic';
    src: url('../public/fonts/SplineSansMono-BoldItalic.ttf') format('truetype');
}

/* Devanagari Language Fonts */
@font-face {
    font-family: 'NotoSerifDevanagari-Light';
    src: url('../public/fonts/NotoSerifDevanagari-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'NotoSerifDevanagari-Regular';
    src: url('../public/fonts/NotoSerifDevanagari-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'NotoSerifDevanagari-Medium';
    src: url('../public/fonts/NotoSerifDevanagari-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'NotoSerifDevanagari-SemiBold';
    src: url('../public/fonts/NotoSerifDevanagari-SemiBold.ttf') format('truetype');
}

@font-face {
    font-family: 'NotoSerifDevanagari-Bold';
    src: url('../public/fonts/NotoSerifDevanagari-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'NotoSerifDevanagari-ExtraBold';
    src: url('../public/fonts/NotoSerifDevanagari-ExtraBold.ttf') format('truetype');
}

@font-face {
    font-family: 'NotoSerifDevanagari-Black';
    src: url('../public/fonts/NotoSerifDevanagari-Black.ttf') format('truetype');
}

::-webkit-scrollbar {
    height: 6px;
    width: 8px;
    border-radius: 100px;
}

::-webkit-scrollbar-track-piece {
    background-color: #FFF;
    border-radius: 100px;
}

::-webkit-scrollbar-thumb {
    background-color: #ff964d;
    outline: 2px solid #FFF;
    outline-offset: -2px;
    border: 0.1px solid #B7B7B7;
    border-radius: 100px;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #909090;
}

@layer base {
    input[type='number']::-webkit-outer-spin-button,
    input[type='number']::-webkit-inner-spin-button,
    input[type='number'] {
        -webkit-appearance: none;
        margin: 0;
        -moz-appearance: textfield !important;
    }
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -ms-overflow-style: none; /* IE and Edge */
}

body::-webkit-scrollbar {
    display: none;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Hide Scrollbar */
@layer utilities {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }

    .thin-scrollbar::-webkit-scrollbar {
        height: 6px;
        width: 6px;
        border-radius: 100px;
    }

    /* Show scrollbar for Chrome, Safari and Opera */
    .show-scrollbar::-webkit-scrollbar {
        display: block;
    }

    /* Show scrollbar for IE, Edge and Firefox */
    .show-scrollbar {
        -ms-overflow-style: auto; /* IE and Edge */
        scrollbar-width: auto; /* Firefox */
    }
}

#arrow-icon-tip {
    transform: translateX(-0.1vw);
    transition: all 150ms ease-in-out;
}

#arrow-icon-line {
    opacity: 0;
    transition: all 150ms ease-in-out;
}

.arrow-button:hover #arrow-icon-tip {
    transform: translateX(0vw);
}

.arrow-button:hover #arrow-icon-line {
    opacity: 1;
}

.react-flow__handle.connection {
    background: #ff6060;
}

.react-flow__handle.valid {
    background: #55dd99;
}

input[type="date"]::-webkit-calendar-picker-indicator, input[type="month"]::-webkit-calendar-picker-indicator {
    /* Customize the icon here */
    /*margin-left: -10px;*/
    background-image: url('../public/assets/calendar-icon-full.svg');
    background-size: cover;
}

input[type="month"]::-webkit-calendar-picker-indicator {
    /*margin-left: -2px; !* Adjust value as needed *!*/
}

.filters-box-shadow {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}
